import React from 'react';
import StandardOutboundLink from './standard-outbound-link';

const QuoteStripeNathan = () => (
  <div className="stripe-hr">
    <q>
      I love how easy it is to actually do the coding.
      It truly requires no previous coding experience or knowledge,
      yet at the same time the more you work with it the more conceptual
      understanding you get with it. Plus it&apos;s fun;
      that&apos;s a huge plus!
    </q>
    <br />
    <br />
    - Nathan, Executive Director at
    {' '}
    <StandardOutboundLink href="https://www.explorationhighschool.org/" className="white-link">
      Exploration High School
    </StandardOutboundLink>
  </div>
);

export default QuoteStripeNathan;
