import React from 'react';
import { Link } from 'gatsby';
import NavigationButton from './navigation-button';
import VideoSection from './video-section';
import QuoteStripeNathan from './quote-stripe-nathan';

const TournamentPricing = () => (
  <>
    <div className="two-wide-container">
      <div className="pricing-panel">
        <div className="panel-title">Run A Tournament</div>
        <div className="panel-body">
          <div className="two-wide-container" style={{ padding: '5px' }}>
            <div>
              Run your very own Code Championship Tournament
              for one group of students.
            </div>
            <div className="discount-pricing">
              <span className="discount-price">$19</span>
            </div>
          </div>
          <div className="feature-list">
            <div className="feature-item">Challenge access</div>
            <div className="feature-item">Bot creation</div>
            <div className="feature-item">1 vs 1 competitive play</div>
            <div className="feature-item">Up to 64 competitors</div>
            <div className="feature-item">Competitive pool play</div>
            <div className="feature-item">Competitive bracket play</div>
            <div className="feature-item">Buy now. Run whenever!</div>
          </div>
          <div>
            <p>
              No experience necessary to run a tournament. We also have
              {' '}
              <Link to="/tournament/instructions/">
                detailed instructional videos
              </Link>
              !
            </p>
          </div>
          <NavigationButton
            path="/tournament/new/"
            text="Let's Go!"
          />
        </div>
      </div>
      <div className="pricing-panel">
        <div className="panel-title">Ten Tournament Bundle</div>
        <div className="panel-body">
          <div className="two-wide-container" style={{ padding: '5px' }}>
            <div>
              Run 10 Tournaments.
              Perfect for teachers with multiple class periods.
            </div>
            <div className="discount-pricing">
              <span className="discount-price">$95</span>
            </div>
          </div>
          <div className="feature-list">
            <div className="feature-item">Challenge access</div>
            <div className="feature-item">Bot creation</div>
            <div className="feature-item">1 vs 1 competitive play</div>
            <div className="feature-item">Up to 64 competitors</div>
            <div className="feature-item">Competitive pool play</div>
            <div className="feature-item">Competitive bracket play</div>
            <div className="feature-item">Buy now. Run whenever!</div>
            <div className="feature-item">Best value</div>
          </div>
          <div>
            <p>
              Purchase ten tournaments today to save 50% and run whenever you are ready!
            </p>
          </div>
          <NavigationButton
            path="/tournament/ten-new/"
            text="Save 50%"
          />
        </div>
      </div>
    </div>
    <QuoteStripeNathan />
    <div className="two-wide-container">
      <div>
        <h2>Let&apos;s Do Something Big!</h2>
        <p>
          If you have questions or would like to partner together to run a tournament,
          we might need to do some planning before we finalize things.
        </p>
        <p>
          Send us an email at tournaments@codechampionship.com
        </p>
      </div>
      <div>
        <VideoSection
          src="https://www.youtube.com/embed/n4L4gWoZJBU?rel=0"
          title="Code Championship at Minnedemo 2019"
        />
      </div>
    </div>
  </>
);

export default TournamentPricing;
