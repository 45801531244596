import React from 'react';
import Layout from '../../components/layout';

import TournamentPricing from '../../components/tournament-pricing';

const TournamentPricingPage = () => (
  <Layout>
    <h1>Tournament Pricing</h1>
    <div className="stripe-hr thin" />
    <p>
      Regardless of your budget, we are here for
      educators looking to bring the excitement of Code Championship
      into their in-person or virtual classrooms.
    </p>
    <TournamentPricing />
  </Layout>
);

export default TournamentPricingPage;
